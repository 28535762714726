import { createApp } from 'vue'

import App from './App.vue'

const app = createApp(App)

import { OhVueIcon, addIcons } from "oh-vue-icons"
import { MdSkipnextRound, MdSkippreviousRound, MdShare } from "oh-vue-icons/icons"
addIcons(MdSkipnextRound, MdSkippreviousRound, MdShare)
app.component('v-icon', OhVueIcon)

import router from './router'
app.use(router)

app.mount('#app')

// expose app to global scope
window.app = app