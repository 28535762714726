import { createRouter, createWebHistory } from 'vue-router'
import { logEvent } from 'firebase/analytics'
import { analytics } from './firebase'

import App from './App.vue'

const routes = [
  { path: '/', component: App },
  { path: '/:org', component: App },
  { path: '/:org/:video', component: App },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  logEvent(analytics, 'page_view', { page_path: to.fullPath })
  next()
})


export default router
