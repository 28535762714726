import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, httpsCallable } from 'firebase/functions'

const firebaseConfig = {
    apiKey: "AIzaSyCJ5cFBgmFwrP0uJM8p5ArXL1acTcR_iMU",
    authDomain: "global-inspired-by-x90oty.firebaseapp.com",
    projectId: "global-inspired-by-x90oty",
    storageBucket: "global-inspired-by-x90oty.appspot.com",
    messagingSenderId: "511049244598",
    appId: "1:511049244598:web:9281d1bfae2ba2a043eb17",
    measurementId: "G-YD2745CSZC"
};


export const firebaseApp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(firebaseApp)

const functions = getFunctions(firebaseApp)

export const videosAndOrganisations = httpsCallable(functions, 'videos')
